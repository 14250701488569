.App {
  text-align: center;
}

.App-header {
  /* background: linear-gradient(to bottom, #e9dcfd, #ffffff); */
  background: linear-gradient(to bottom right, #e9dcfd, #ffffff);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(34, 29, 29);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* CSS */
.button-51 {
  background-color: #a992ee;
  border: 1px solid #000000;
  border-radius: 5px;
  box-sizing: border-box;
  color: #00132C;
  font-family: "Avenir Next LT W01 Bold",sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 16px 23px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-51:hover {
  background-color: #d6ccf3;
  cursor: pointer;
}

@media (min-width: 768px) {
  .button-51 {
    padding: 16px 32px;
  }
}

.logo {
  /* background-color: white;
  border-radius: 100px; */
  position: absolute;
  margin-left: 20px;
  top: 10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  width: 50px; /* Adjust the width as needed */
  height: auto;
}

.mobileapp {
  width: 500px; /* Adjust the width as needed */
  height: auto;
}

.fade-in {
  opacity: 0;
  /* animation: fadeIn 3s ease forwards; */
  animation: fadeIn 0.5s ease forwards, slideIn 1s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(40px); /* Adjust the vertical distance as needed */
  }
  to {
    transform: translateY(0);
  }
}